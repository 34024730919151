<template>
  <div class="settings-wraper">
    <!-- frame -->
    <el-tooltip
      placement="bottom"
      content="frame"
      transition="fade-tooltip"
      :disabled="device === 'mobile'"
    >
      <el-dropdown
        placement="bottom"
        :class="{ selected: frame === 'radius-frame' }"
        trigger="click"
      >
        <img
          alt="img"
          @click="frame = 'radius-frame'"
          class="icons"
          src="@/assets/img/qr/Rectangle_radius.svg"
        />
        <el-dropdown-menu class="frame-sliders" slot="dropdown">
          <el-slider
            class="slider"
            :show-tooltip="false"
            :min="frameParams.minWidth"
            :max="frameParams.maxWidth"
            v-model="frameSettings.frameWidth"
          />
          <el-slider
            class="slider"
            :show-tooltip="false"
            :min="frameParams.minRadius"
            :max="frameParams.maxRadius"
            v-model="frameSettings.frameRadius"
          />
          <el-dropdown-item @click.native.stop class="dropdown-item">
            <img
              alt="img"
              @click="frame = 'no-frame'"
              class="icons no-frame"
              src="@/assets/img/qr/no-frame.svg"
            />
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-tooltip>
    <!-- transparent -->
    <el-tooltip
      placement="bottom"
      content="transparency"
      transition="fade-tooltip"
      :disabled="device === 'mobile'"
    >
      <label class="transparent" :class="{ selected: transperancy }">
        <input type="checkbox" hidden v-model="transperancy" />
        <img alt="img" class="icons" src="@/assets/img/qr/transparency.svg" />
      </label>
    </el-tooltip>
    <!-- color -->
    <colorPicker
      class="color-picker"
      :colorObject="color"
      @input="color = $event"
      :hoverPosition="'left-hover'"
      :hoverTitle="'color'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import colorPicker from "@/components/contextMenu/utils/colorPicker";

export default {
  name: "PilQrSettings",
  components: {
    colorPicker
  },
  computed: {
    ...mapGetters({
      device: "workSpace/device",
      frameParams: "qr/frameParams",
      settings: "qr/qrSettings"
    }),
    transperancy: {
      get() {
        return this.settings.transperancy;
      },
      set(value) {
        this.$store.commit("qr/updateSettings", {
          prop: "transperancy",
          value
        });
      }
    },
    frame: {
      get() {
        return this.settings.frame;
      },
      set(value) {
        this.$store.commit("qr/updateSettings", {
          prop: "frame",
          value
        });
      }
    },
    color: {
      get() {
        const color = this.settings.color;
        return { hex: color, hex8: color };
      },
      set({ hex8 }) {
        this.$store.commit("qr/updateSettings", {
          prop: "color",
          value: hex8
        });
      }
    }
  },
  data() {
    return {
      frameSettings: {
        frameWidth: 0,
        frameRadius: 0
      }
    };
  },
  created() {
    const defSettings = this.$store.getters["qr/frameSettings"];
    this.frameSettings.frameWidth = defSettings.frameWidth;
    this.frameSettings.frameRadius = defSettings.frameRadius;
  },
  methods: {
    updateSettings({ frameWidth, frameRadius }) {
      this.$store.commit("qr/updateFrameSettings", {
        prop: "frameWidth",
        value: frameWidth
      });
      this.$store.commit("qr/updateFrameSettings", {
        prop: "frameRadius",
        value: frameRadius
      });
    }
  },
  watch: {
    frameSettings: {
      handler: "updateSettings",
      deep: true
    }
  }
};
</script>

<style scoped>
.settings-wraper {
  margin: 6px auto 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #f8f8f5;
  border-radius: 6px;
  width: 200px;
  box-sizing: border-box;
  padding: 0 8px;
  height: 50px;
}
.el-dropdown-menu__item {
  display: flex;
  flex-direction: column;
}
.slider {
  padding: 0 10px;
  box-sizing: border-box;
}
.no-frame {
  padding: 3px;
  margin: auto;
}
.color-settings-wraper {
  display: flex;
  justify-content: center;
}
.transparent,
.color-picker,
.el-dropdown {
  display: flex;
  padding: 5px;
  border-radius: 7px;
  border: 1px solid #ffffff00;
  cursor: pointer;
}
.selected {
  border-color: #ffe000;
}
</style>
