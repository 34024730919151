<template>
  <div
    class="qr-wraper"
    @click="
      $store.dispatch('qr/addToCanvas', {
        qrSvg: qrCode
      })
    "
    :style="!qrSettings.transperancy && { background: '#ffffff' }"
  >
    <div :style="frameWraperStyle" class="screenshot">
      <qr-code @qr-render="onQRrender" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import qrCode from "./QrCode";

export default {
  name: "PilQrCard",
  data() {
    return {
      toCanvas: null,
      svg: ""
    };
  },
  components: { qrCode },
  computed: {
    ...mapGetters({
      canvas: "workSpace/canvas",
      device: "workSpace/device",
      qrSettings: "qr/qrSettings",
      frameSettings: "qr/frameSettings",
      qrValue: "qr/qrValue",
      defaultValue: "qr/defaultValue"
    }),
    frameWraperStyle() {
      const frameType = this.qrSettings.frame;
      const frameWidth = this.frameSettings.frameWidth;
      const frameRadius = this.frameSettings.frameRadius;
      const borderWidth = !(frameType === "no-frame") ? frameWidth : 0;
      const borderRadius = frameType === "radius-frame" ? frameRadius : 0;
      const margin = this.getMargin(borderWidth, borderRadius, frameType);
      return {
        margin,
        backgroundColor: this.qrSettings.transperancy
      };
    }
  },
  methods: {
    getMargin(borderWidth, borderRadius, frameType) {
      let margin = 30 - (borderRadius * 4) / 10 - borderWidth;
      margin = !(frameType === "no-frame") ? margin + "px" : margin + 10 + "px";
      return margin;
    },
    onQRrender(qr) {
      this.qrCode = qr;
    }
  }
};
</script>

<style scoped>
.qr-wraper {
  background-image: url(../../../assets/img/qr/card_bg.png);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  box-sizing: padding-box;
  width: 220px;
  align-self: center;
  cursor: pointer;
}
.screenshot {
  display: flex;
}
</style>
