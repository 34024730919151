<template>
  <div>
    <div class="frame" :style="frameStyle">
      <div class="qr-code" v-html="qrcode"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PilQrcode",
  data() {
    return {
      qrcode: ""
    };
  },
  computed: {
    ...mapGetters({
      qrSettings: "qr/qrSettings",
      frameSettings: "qr/frameSettings",
      qrValue: "qr/qrValue",
      defaultValue: "qr/defaultValue"
    }),
    frameStyle() {
      const frameType = this.qrSettings.frame;
      const frameWidth = this.frameSettings.frameWidth;
      const frameRadius = this.frameSettings.frameRadius;
      const borderWidth = !(frameType === "no-frame") ? frameWidth + "px" : 0;
      const borderRadius = frameType === "radius-frame" ? frameRadius + "%" : 0;
      const padding = this.getPadding(frameType, borderRadius);
      return {
        padding,
        borderWidth,
        borderRadius,
        borderStyle: "solid",
        borderColor: this.qrSettings.color,
        margin: "5px"
      };
    }
  },
  watch: {
    qrValue: {
      handler: "renderQr"
    },
    "qrSettings.color": {
      handler: "renderQr"
    }
  },
  created() {
    this.renderQr();
  },
  methods: {
    renderQr() {
      this.$store.dispatch("qr/generateQr").then(qrCode => {
        this.qrcode = qrCode;
        this.$emit("qr-render", this.qrcode);
      });
    },
    getPadding(frameType, borderRadius) {
      return !(frameType === "no-frame")
        ? 10 + (parseFloat(borderRadius) * 4) / 10 + "px"
        : "0px";
    }
  }
};
</script>

<style scoped>
.qr-code {
  display: flex;
}
.block {
  margin: 0 auto;
  width: 140px;
}
</style>
