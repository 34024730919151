<template>
  <div>
    <qr-input />
    <div class="qr-container" :style="{ height: contentContainerHeight }">
      <div>
        <qr-settings />
        <qr-card class="container" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import qrInput from "@/components/tabs/qrTab/QrInput";
import qrCard from "@/components/tabs/qrTab/QrCard";
import qrSettings from "@/components/tabs/qrTab/QrSettings";
import "@/assets/css/qr/settingsStyle.css";

export default {
  name: "PilQrTab",
  components: {
    qrInput,
    qrCard,
    qrSettings
  },
  computed: {
    ...mapGetters({
      windowHeight: "workSpace/windowHeight",
      device: "workSpace/device",
      cookiesBannerHeight: "workSpace/cookiesBannerHeight"
    }),
    contentContainerHeight() {
      if (this.device === "mobile")
        return `${(
          (this.windowHeight / 100) * 65 -
          70 -
          this.cookiesBannerHeight
        ).toFixed()}px`;
      return `${(
        this.windowHeight -
        234 -
        this.cookiesBannerHeight
      ).toFixed()}px`;
    }
  }
};
</script>

<style scoped>
.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  padding-top: 0;
  margin: 0 5px;
  overflow: auto;
  height: calc(100vh - 245px);
}
.container {
  display: flex;
  justify-self: center;
  align-self: center;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .qr-container {
    height: calc(100vh - 335px);
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .qr-container {
    height: calc(100vh - 365px);
  }
}
</style>
