<template>
  <div class="color-picker-conteiner">
    <el-dropdown
      placement="bottom"
      @visible-change="$emit('visible-change', $event)"
      trigger="click"
    >
      <el-tooltip
        placement="bottom"
        :content="hoverTitle"
        transition="fade-tooltip"
        :disabled="device === 'mobile'"
      >
        <div :style="pickerStyle" class="color-picker-wraper"></div>
      </el-tooltip>

      <el-dropdown-menu slot="dropdown">
        <chrome-picker :value="colorObject" @input="$emit('input', $event)" />
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChromePicker from "vue-color/src/components/Chrome";
import { getColorBorder } from "@/helpers/hex";

export default {
  name: "PilColorPicker",
  props: ["colorObject", "hoverTitle", "hoverPosition"],
  components: {
    ChromePicker
  },
  computed: {
    ...mapGetters({
      device: "workSpace/device"
    }),
    pickerStyle() {
      if (!this.colorObject)
        return {
          border: `1px solid #ffe000`
        };
      return {
        backgroundColor: this.colorObject?.hex8 ?? this.colorObject?.hex,
        border: getColorBorder(this.colorObject)
      };
    }
  }
};
</script>

<style scoped>
.border-color {
  cursor: pointer;
  position: relative;
}
.color-picker-wraper {
  width: 22px;
  height: 22px;
}
</style>
