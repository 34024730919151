<template>
  <el-dropdown placement="bottom" class="search-dropdown" trigger="click">
    <div class="search-input-wraper">
      <input class="search-input" type="text" v-model.trim="input" />
      <img alt="img" src="@/assets/img/select_arrow.svg" class="search-arrow" />
    </div>
    <el-dropdown-menu class="items-container" slot="dropdown">
      <el-dropdown-item
        v-show="myInteractions.length"
        class="item"
        @click.native="onSelectInteraction(i.link)"
        v-for="(i, idx) of myInteractions"
        :key="idx"
      >
        {{ i.name }}
      </el-dropdown-item>
      <div v-show="!myInteractions.length" class="msg">
        you have no interactions
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PilQrInput",
  methods: {
    ...mapActions({
      updateValue: "qr/updateValue"
    }),
    onSelectInteraction(link) {
      link;
      const interactionLink = `${process.env.VUE_APP_SURVEY_URL}/${link}`;
      this.$store.dispatch("qr/generateQr", interactionLink).then(qrCode => {
        this.$store.dispatch("qr/addToCanvas", {
          qrSvg: qrCode,
          value: interactionLink
        });
      });
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      qrValue: "qr/qrValue",
      myInteractions: "customContent/myInteractions"
    }),
    input: {
      get() {
        return this.qrValue;
      },
      set(value) {
        this.$store.commit("qr/updateValue", value);
      }
    }
  }
};
</script>

<style scoped>
.search-arrow {
  position: absolute;
  padding: 10px;
  background: #f8f8f5;
  border-radius: 7px;
  right: 20px;
  top: 10px;
  cursor: pointer;
}
.msg {
  padding: 30px 10px;
  text-align: center;
  font-size: 12px;
}
.items-container {
  margin-top: 0;
  width: 270px;
  box-sizing: border-box;
}
.item {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.item:hover {
  background: #f8f8f5;
  color: #000;
}
.search-dropdown {
  width: 100%;
}
</style>
